<template>
	<div class="news_dtl">
		<div class="news_title">
			<p class="news_title_cn">新闻动态</p>
			<p class="news_title_en">NEWS</p>
		</div>

		<div class="news_dtl_con_box">
			<div class="news_dtl_con_title">{{ vData.title }}</div>
			<div class="news_dtl_con" v-html="vData.content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "index",
		data() {
			return {
				vData: {}
			}
		},
		created() {
			this.init();
		},
		methods: {
			async init() {
				this.$loading();
				let params = {
					id: this.$route.params.id
				}

				const res = await this.$api.content(params);
				this.$loading().clear();
				this.vData = res.data;
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.news_dtl {
		width: 100%;
		max-width: 100rem;
		margin: 0 auto;
		padding: 10rem 1rem;
	}

	.news_title {
		color: @greenColor;
		padding-bottom: 2.5rem;
		font-family: 'shangshushui';
	}

	.news_title_cn {
		font-size: 5.4rem;
	}

	.news_title_en {
		font-size: 1.5rem;
		margin-top: 0.5rem;
	}

	.news_dtl_con_box {
		margin-top: 3.2rem;
		padding: 0 3rem;
	}

	.news_dtl_con_title {
		font-size: 4.4rem;
		margin-bottom: 2.5rem;
		color: @greenLightColor;
		text-align: center;
	}

	.news_dtl_con,
	.news_dtl_con /deep/ p {
		font-size: 16px;
		line-height: 1.5;
		text-align: justify;
		min-height: 1rem;
	}

	.news_dtl_con /deep/ img {
		width: 100%;
	}







	@media screen and (max-width: 800px) {
		.news_dtl {
			width: 95%;
			padding: 3.1rem 0;
		}

		.news_title {
			padding-bottom: 0.8rem;
		}

		.news_title_cn {
			font-size: 1.9rem;
		}

		.news_title_en {
			font-size: 0.6rem;
			margin-top: 0.2rem;
		}

		.news_dtl_con_box {
			margin-top: 2.2rem;
			padding: 0 1rem;
		}

		.news_dtl_con_title {
			font-size: 1.5rem;
		}

		.news_dtl_con {
			font-size: 1rem;
		}
	}
</style>